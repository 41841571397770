var checkboxes4i = function(){
	const init = () => {
		const selectAllChecks = document.querySelectorAll(".selectAllBox");
		if(selectAllChecks.length > 0){
			for(let i = 0; i < selectAllChecks.length; i++){
				selectAllChecks[i].addEventListener('click', checkUncheckAll);
			}
			assessChecks();
		}
	};
	const uncheck = (checks) => {
		if(checks.length > 0){
			for(let i = 0; i < checks.length; i++){
				checks[i].checked = false;
			}
		}
	};
	const check = (checks) => {
		if(checks.length > 0){
			for(let i = 0; i < checks.length; i++){
				checks[i].checked = true;
			}
		}
	};
	const checkUncheckAll = (e) => {
		const selectAll = e.currentTarget;
		const checkBoxSection = selectAll.id + "Options";
		const allChecks = document.getElementById(checkBoxSection).querySelectorAll("input[type='checkbox']"); 
		if(selectAll.checked) check(allChecks);
		else uncheck(allChecks);
	};
	const getPreferences = (checksParent) => {
		const checksToCheck = checksParent.querySelectorAll("input[type='checkbox']");
		let prefs = ""; 
		if(checksToCheck.length > 0){
			for(let i = 0; i < checksToCheck.length; i++){
				let checkbox = checksToCheck[i];
				if(checkbox.value != ''){
					if(checkbox.checked) prefs += checkbox.value + ":true,";
					else prefs += checkbox.value + ":false,";
				}
			}
		}
		return prefs;
	};
	const assessChecks = () => {
		const checkboxSections = document.querySelectorAll(".checkboxOptions");
		if(checkboxSections.length > 0){
			for(let i = 0; i < checkboxSections.length; i ++){
				let truthy = true;
				let selectAllBoxID = checkboxSections[i].id.replace("Options", "");
				let allChecks = checkboxSections[i].querySelectorAll("input[type='checkbox']");
				if(allChecks.length > 0){
					for(let x = 0; x < allChecks.length; x++){
						let checkbox = allChecks[x];
						if(checkbox.value != '' && checkbox.checked == false) truthy = false;
					}
					
					let selectAll = document.getElementById(selectAllBoxID);
					if(truthy && selectAll) selectAll.checked = true; 
				}
			}
		}
	};
	
	return{
		Init: init,
		Uncheck: uncheck,
		Check: check,
		GetPreferences: getPreferences,
		AssessChecks: assessChecks
	};
};