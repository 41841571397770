var catalogSubscription = function(){
	const helpers = new formHelpers4i();
	const catalogCheckbox = document.getElementById('CatalogCheckbox');
	const messages = {
		//Error: "Your subscription preferences failed to update. If this continues please <a href='/info/contactus'>contact us</a> for assistance.",
		Success: "Your subscription preferences have been updated. Continue to the homepage to <a href='/'>start shopping</a>."
	};
	
	const init = () => {
		const isPublicESP = document.getElementById("hdnEmail");
    		if(!isPublicESP && catalogCheckbox) updateCheck();
    		
    		//TODO is this necessary?
    		if(catalogCheckbox) catalogCheckbox.addEventListener('change', updateHiddenInput);
	};
	const updateHiddenInput = (e) =>{
		const hiddenInput = document.getElementById("CatalogSubscribe");
		if(hiddenInput){
			if(catalogCheckbox.checked) hiddenInput.value = "true";
			else hiddenInput.value = "false";
		}		
	};
	const updateCheck = async () => {
		catalogCheckbox.checked = false;
		
		const registerForm = document.getElementById("registerForm");
	    	if(registerForm) catalogCheckbox.checked = true;
			
		let responseData;
		try{
			const response = await fetch('/Catalog/CatalogSubscription', {
				method: 'GET', 
				headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'fetch' }
			});
			if(!response.ok) throw new Error(`Error processing catalog subscription request: ${response.statusText}`);
			responseData = await response.text();
		}catch(error){
			console.error(error);
			// error function here
		}
		if(responseData && responseData.toString().toLowerCase() == "true") catalogCheckbox.checked = true;
		updateHiddenInput();
	};
	const unsubscribe = async () => {
		//let responseData;
		try{
			const response = await fetch('/Catalog/UnsubscribeCatalogSubscription', {
				method: 'POST', 
				headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'fetch', "__RequestVerificationToken": helpers.HeaderToken, "RequestVerificationToken": helpers.HeaderToken }
			});
			if(!response.ok) throw new Error(`Error processing unsubscribe catalog request: ${response.statusText}`);
			//responseData = await response.text();
		}catch(error){
			console.error(error);
			// error function here
		}
		
		catalogCheckbox.checked = false;
	};
	const update = async (e) => {
		if(e) helpers.ShowBusyBtn(e.currentTarget);
		
		let url = "";
	       if (catalogCheckbox.checked == true) url = "/Catalog/SubscribeCatalogSubscription";
	       else url = "/Catalog/UnsubscribeCatalogSubscription";
	       	   
	     	//let responseData;
		try{
			const response = await fetch(url, {
				method: 'POST', 
				headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'fetch', "__RequestVerificationToken": helpers.HeaderToken, "RequestVerificationToken": helpers.HeaderToken }
			});
			if(!response.ok) throw new Error(`Error processing catalog subscription request: ${response.statusText}`);
			//responseData = await response.text();
			if(e) success(); // only when coming from account catalog page
		}catch(error){
			console.error(error);
			// error function here
		}
	};
	const success = () => {
		helpers.ClearBusyBtn();
		helpers.ShowSuccessStatus(document.getElementById("SaveChanges"), messages.Success);
	};
	return{
		Init: init,
		Unsubscribe: unsubscribe,
		Update: update
	};
};