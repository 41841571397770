/* 
	Joe Wakahiu 		01.17.22
	on pageload:		const maps = new maps4i();
	enable:  			maps.init();
	css needed: 			   
	usage:			1. Add this JavaScript file to an existing bundle and make sure Address Line 1 input field on the intended form has a name attribute: name='Address1'.
					2. For a dynamically added form, initialize maps api as follows to add the Address1 input event listener:
						var maps = new maps4i();
						maps.init();
					3. If maps4i object already exists, use maps.BindAddressAutoCompleteEvents() to reactivate address line 1 event listener on the new element..
*/
var maps4i = function () {
	"use strict";
	
	let addressOneInput = null;
	let suggestedList = [];
	let form = null;
	let autoTimer = null;
	const loading = '<div id="addressLoading" class="spinner" role="alert" aria-busy="true" aria-atomic="true" aria-label="Loading address suggestions, please wait"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div>';
	const headerToken = document.querySelector('input[name=__RequestVerificationToken]') ? document.querySelector('input[name=__RequestVerificationToken]').value : "";
	
	const init = () =>{
		bindAddressAutoCompleteEvents();			
	};
	
	const bindAddressAutoCompleteEvents = () =>{
		const addressFields = [].slice.call(document.getElementsByName("Address1"));
		for(let i = 0; i < addressFields.length; i++){
			addressFields[i].addEventListener('keyup', userTyping);
		}	
	};
	
	const userTyping = (e) => {
		const currentInput = e.currentTarget;
		if (autoTimer != null) clearTimeout(autoTimer);
		autoTimer = setTimeout(() => { fetchAddresses(currentInput); }, 500); 
	};
	
	const fetchAddresses = (el) => { 
		resetSuggestions();
		if (el.value.length >= 3) {
			const addressParts = el.value.trim().split(' '); //.split('/\s+/');
			let resultsFound = false;
			if(addressParts.length > 1 && addressParts[1].length > 0){ //verify we have at least two parts of address line1 		
				addressOneInput = el;
 				form = addressOneInput.closest('form');
 				addressOneInput.parentNode.classList.add("posRelative");
 				setupDropdown();
 				let header = { 'Content-Type': 'application/json', 'X-Requested-With': 'fetch',  '__RequestVerificationToken': headerToken, 'RequestVerificationToken': headerToken };
 				
 				if(window.location.hostname.indexOf('outlet') > -1) {
 					//We need to remove underscores from RequestVerificationToken for ASP .NetCore
 					header = { 'Content-Type': 'application/json', 'X-Requested-With': 'fetch',  'RequestVerificationToken': headerToken };
 				}
 				
				fetch('/maps/api/addresssuggestions', {
					method: 'POST',
					credentials: 'same-origin',
					body: JSON.stringify({ query: encodeURIComponent(addressOneInput.value.trim()) }),
					headers: header
				}).then(function (response){
					return (response.ok) ? response.json() : Promise.reject(response);
				}).then(function (data){
					if(data && data.Success){
						if(data.AddressSuggestions && data.AddressSuggestions.length > 0) {
							resultsFound = true;
							suggestedList = data.AddressSuggestions;
							const addyContainer = document.getElementById('address-suggestions-container');
							addyContainer.innerHTML = data.AddressListPartialView;	
							setUpA11yMessage(data.AddressSuggestions.length);
							document.addEventListener("click", closeOnOutsideClick);					
							bindAddressSuggestions();						
						} 
					}
					
					if(!resultsFound) resetSuggestions();
				}).catch(function(error) {
					console.log("Address autocompete search failed");
				});	
			}
		}
	};
	
	const setUpA11yMessage = (addyOpts) =>{
		const statusMessage = '<p id="autoCompMessage" class="visually-hidden" role="alert" aria-atomic="true">' + addyOpts + ' addresses found</p>';
		const autoMessage = document.getElementById("autoCompMessage");
		if(autoMessage) autoMessage.remove();
		const suggestContainer = document.getElementById('address-suggestions-container');
		suggestContainer.insertAdjacentHTML("afterbegin", statusMessage); 
	};
	
	const resetSuggestions = () =>{
		suggestedList = [];
		const suggestContainer = document.getElementById('address-suggestions-container');
		if(suggestContainer){
			suggestContainer.remove();
			if(addressOneInput) addressOneInput.parentNode.classList.remove("posRelative");
			document.removeEventListener("click", closeOnOutsideClick);
		}
		
		if(addressOneInput) addressOneInput = null;		  	
	};	
	
	const closeOnOutsideClick = (e) =>{
		resetSuggestions(); //TODO?
	};
	
	const populateFields = (e)  => {
		e.preventDefault();
		
		const addressSelected = e.currentTarget;
		const addressIndex = addressSelected.querySelector('input[name=address-suggestion-index]').value;
		const streetNumber = suggestedList[addressIndex].StreetNumber != null && suggestedList[addressIndex].StreetNumber != "" ? suggestedList[addressIndex].StreetNumber : "";
		const streetName = suggestedList[addressIndex].StreetName != null && suggestedList[addressIndex].StreetName != "" ? suggestedList[addressIndex].StreetName : "";
		form.Address1.value = (streetNumber + ' ' + streetName).trim();
		form.City.value = suggestedList[addressIndex].Municipality != null && suggestedList[addressIndex].Municipality != "" ? suggestedList[addressIndex].Municipality : "";
		form.State.value = suggestedList[addressIndex].CountrySubdivision != null && suggestedList[addressIndex].CountrySubdivision != "" ? suggestedList[addressIndex].CountrySubdivision : "";		
		
		let postalElement;
		if(form.ZipCode) postalElement = form.ZipCode;
		else if(form.Zipcode) postalElement = form.Zipcode;
		
		const extendedPostalCode = suggestedList[addressIndex].ExtendedPostalCode != null && suggestedList[addressIndex].ExtendedPostalCode != "" ?suggestedList[addressIndex].ExtendedPostalCode : "";
		const postalCode = suggestedList[addressIndex].PostalCode != null && suggestedList[addressIndex].PostalCode != "" ? suggestedList[addressIndex].PostalCode : "";
		if(suggestedList[addressIndex].ExtendedPostalCode && suggestedList[addressIndex].ExtendedPostalCode.split(',').length < 2)
			postalElement.value = extendedPostalCode.trim();
		else postalElement.value = postalCode.trim();

		resetSuggestions();
	};
	
	const bindAddressSuggestions = () => {
		const addresses = document.getElementsByClassName("address-suggestion-item");
		for(let i = 0; i < addresses.length; i++){
			let el = addresses[i].querySelector("a");
			el.addEventListener('click', populateFields);
			el.addEventListener('keypress', function(e){
				if(e.keyCode === 13) populateFields(e);
			});
		}	
	};
	
	const setupDropdown = () =>{
		const dropContainer = document.createElement("div");
		dropContainer.id = 'address-suggestions-container';
		dropContainer.classList.add("address-suggestions-container", "posAbsolute", "borderMdGray", "text16", "bkgdWhite", "fullWidth");
		dropContainer.innerHTML = loading;
		addressOneInput.insertAdjacentElement('afterend', dropContainer);			
	};

	return {
		init: init,
		BindAddressAutoCompleteEvents: bindAddressAutoCompleteEvents,
       	fetchAddresses: fetchAddresses,
       	resetSuggestions: resetSuggestions,
       	populateFields: populateFields,
       	bindAddressSuggestions: bindAddressSuggestions
    	};
};

(async () => {
	const maps = new maps4i();
	maps.init();
})();