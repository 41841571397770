var register4i = function () {
	const forms = new forms4i();
	const addresses = new addressValidation4i();
	const helpers = new formHelpers4i();
	const checkboxes = new checkboxes4i();
	const submitBtn = document.getElementById("btnSubmit");

	const init = () => {
		maskZipPhone();

		const submitBtn = document.getElementById("btnSubmit");
		const registerForm = document.getElementById("registerForm");
		registerForm.addEventListener("submit", function () {
			submitBtn.disabled = true;
		});
		submitBtn.addEventListener("click", validateFormInfo);
	};

	const maskZipPhone = () => {
		const zipMask = document.getElementById("ZipCodeMask");
		if (zipMask) {
			const zipCodeMaskPattern = zipMask.value;
			const zipCode = document.getElementById("ZipCode");
			// only used for CA
			if (zipCodeMaskPattern != "") VMasker(zipCode).maskPattern(zipCodeMaskPattern);
		}

		const phoneMask = document.getElementById("phoneMask");
		const phone = document.getElementById("Phone");
		const cell = document.getElementById("MobileNumber");
		forms.MaskPhoneNumber(phoneMask, phone);
		forms.MaskPhoneNumber(phoneMask, cell);
	};

	const validateFormInfo = (e) => {
        e.preventDefault();
		submitBtn.removeEventListener("click", validateFormInfo);
		submitBtn.addEventListener("click", submitForm);

		addresses.validateAddress(() => {
			submitBtn.click();
		});
	};

	const submitForm = (e) => {
		
		// using this ID on all forms in registration
		helpers.ClearStatus();
		helpers.ShowBusyBtn(e.target);
		const registerForm = document.getElementById("registerForm");
		if (registerForm) forms.formValidation(registerForm, formSuccess, e, helpers.RemoveBusyBtn);
	};

	const formSuccess = () => {
		const email = document.getElementById('Email');
		const registerForm = document.querySelector(".RegistrationForm");
		if (email && registerForm) saveEmailPreferences(email.value);
	};

	const saveEmailPreferences = async (emailAddress) => {
		const emailPreferences = checkboxes.GetPreferences(document.querySelector(".userSubscriptions"));
		const newUserData = { emailPrefs: emailPreferences, emailAddress: emailAddress };

		try {
			const response = await fetch('/Esp/SaveNewUserSubscriptions', {
				method: 'POST',
				body: JSON.stringify(newUserData),
				headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'fetch', "__RequestVerificationToken": helpers.HeaderToken, "RequestVerificationToken": helpers.HeaderToken }
			});
			if (!response.ok) throw new Error(`Error processing register user subscription request: ${response.statusText}`);
		} catch (error) {
			console.log(error);
		}
	};
	return {
		Init: init
	};
};

(async () => {
	const validateRegForms = new register4i();
	validateRegForms.Init();
})();